import axios from "@/axios/axios.js";
import axiosN from "axios";
const funds = {
  //获取所有汇率
  async getAllExhangeRate(params = {}) {
    return await axios.post("/uc/deposit/getRate", params, {
      des: "直客wap获取注资汇率",
      sTime: new Date().getTime(),
    });
  },
  // 提现
  async withdraw(params) {
    return await axios.post("/uc/gold/submitGold", params, {
      des: "直客wap提现",
      sTime: new Date().getTime(),
    });
  },
  // 获取收币地址
  async getBindCodeList(params) {
    return await axios.get(
      "/uc/user/bindcode/list",
      { params },
      { des: "获取收币地址", sTime: new Date().getTime() }
    );
  },
  // 添加收币地址
  async addBindCodeList(params) {
    return await axios.post("/uc/user/bindcode/add", params, {
      des: "添加收币地址",
      sTime: new Date().getTime(),
    });
  },
  // 修改收币地址
  async updateBindCodeList(params) {
    return await axios.post("/uc/user/bindcode/update", params, {
      des: "修改收币地址",
      sTime: new Date().getTime(),
    });
  },
  // 删除收币地址
  async deleteBindCodeList(params) {
    return await axios.post("/uc/user/bindcode/del", params, {
      des: "删除收币地址",
      sTime: new Date().getTime(),
    });
  },
  // 出金手续费确认
  async poundageConfirm(params) {
    return await axios.post("/uc/gold/poundageConfirm", params, {
      des: "直客wap出金手续费确认",
      sTime: new Date().getTime(),
    });
  },
  //用户中心-真实账户-资金管理-注资记录查询
  async getDepositList(params) {
    return await axios.post("/uc/deposit/query", params, {
      des: "直客wap注资记录查询",
      sTime: new Date().getTime(),
    });
  },
  //用户中心——资金管理——出金
  async getOutGoldList(params) {
    return await axios.post("/uc/gold/list", params, {
      des: "直客wap出金",
      sTime: new Date().getTime(),
    });
  },
  // 获取提现界面的信息
  async getApplyGoldInfo(params = "") {
    return await axios.post("/uc/gold/applyGold", params, {
      des: "直客wap获取提现界面的信息",
      sTime: new Date().getTime(),
    });
  },
  //用户取消出金
  async giveup(params) {
    return await axios.post("/uc/gold/giveup", params, {
      des: "直客wap用户取消出金 ",
      sTime: new Date().getTime(),
    });
  },
  // 获取注销账号信息
  async getCancellationInfo(params) {
    return await axios.get(
      "/uc/user/userinfo/condition",
      { params },
      { des: "注销条件获取", sTime: new Date().getTime() }
    );
  },
  //用户中心-注销账户
  async getCancelLation(params) {
    return await axios.post("/uc/user/userinfo/cancellation", params);
  },
  //获取撮合订单详情
  async getWithdrawalDetail(params) {
    return await axios.post("/uc/gold/withdrawalDetail", params, {
      des: "获取撮合订单详情",
      sTime: new Date().getTime(),
    });
  },
  //确认撮合订单
  async paymentSuccessful(params) {
    return await axios.post("/uc/gold/paymentSuccessful", params, {
      des: "确认撮合订单",
      sTime: new Date().getTime(),
    });
  },
  //获取提现方式等信息
  async getWithdrawMode(params) {
    return await axios.get("uc/gold/qrcode", params, {
      des: "获取提现方式等信息",
      sTime: new Date().getTime(),
    });
  },
  //获取OSS验证签名
  async getCallbackSign(params) {
    return await axios.get("common/callbackSign", params, {
      des: "获取OSS验证签名",
      sTime: new Date().getTime(),
    });
  },
  //获取图片url
  async getPictureUrl(params) {
    return await axios.post("common/getPictureUrl", params, {
      des: "获取图片url",
      sTime: new Date().getTime(),
    });
  },
  // 获取用户信息判断是否绑定手机、IDCard
  async getIdentityInfo(params = "") {
    return await axios.post("/uc/gold/isAuthentication", params, {
      des: "获取用户信息判断是否绑定手机、IDCard",
      sTime: new Date().getTime(),
    });
  },
  //获取出金时手续费比例
  async getServiceCharge(params) {
    return await axios.get("uc/gold/getServiceCharge", params, {
      des: "获取出金时手续费比例",
      sTime: new Date().getTime(),
    });
  },
  // 选择银行列表
  async getChoiceBankList(params = "") {
    return await axios.post("/uc/gold/choiceBankList", params, {
      des: "出金选择银行列表",
      sTime: new Date().getTime(),
    });
  },
  //关闭交易现金活动
  async getCloseTradeCashActivity(params = "") {
    return await axios.post('uc/gold/closeTradeCashActivity', params, {
      des: '关闭交易现金活动',
      sTime: new Date().getTime()
    });
  },
  //出金图片上传ftp
  async upload2sftp(params) {
    const options = {
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
        token: localStorage.getItem("token"),
      },
      data: params,
      url: "apis/common/upload2sftp",
    };
    return await axiosN(options);
  },
};

export default funds;
