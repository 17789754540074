<template>
  <div class="funds-details">
    <h1 class="h1">收支明细</h1>
    <Tab
      ref="fundsTab"
      :tabList="tabList"
      @changeTab="handleChangeTab"
      :tabIndex="tabIndex"
    />
    <div class="search">
      <div class="date-search">
        <el-date-picker
          v-if="!isWap"
          v-model="searchDate"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        />
        <div v-else class="search-input">
          <input
            class="input"
            type="text"
            placeholder="起始日期"
            v-model="searchDate[0]"
            @click="datetimeInput('s')"
          />
          <span class="search-line">-</span>
          <input
            class="input"
            type="text"
            placeholder="结束日期"
            v-model="searchDate[1]"
            @click="datetimeInput('e')"
          />
        </div>
        <button class="button btn-search" @click="handleSearch">查询</button>
        <button class="button btn-reset" @click="handleReset">重置</button>
      </div>
      <div class="count-info">
        <span class="count-num">当前{{ totalNum }}条</span>
        <span class="count-money">总金额： ${{ totalGold }}</span>
      </div>
    </div>
    <van-list
      class="funds-list"
      v-model="loading"
      :offset="15"
      :finished="finished"
      @load="infinite"
      v-if="tabIndex == 1"
      finished-text="暂无更多..."
    >
      <div class="funds-item" v-for="item in list" :key="item.orderNumber">
        <div class="item-date">
          <div class="m-left">订单号：{{ item.orderNumber }}</div>
          <div class="m-right">{{ item.createTime }}</div>
        </div>
        <div class="item-info">
          <div class="info info-01">
            <p class="info-money">{{ item.mjMoney }}</p>
            <p class="info-name">金额(美元)</p>
          </div>
          <div class="info info-02">
            <p class="info-money">{{ item.depositType }}</p>
            <p class="info-name">类型</p>
          </div>
          <!-- 入金状态 -->
          <div
            :class="
              item.status == '存款成功'
                ? 'm-color-green'
                : item.status == '存款失败'
                ? 'm-color-red'
                : item.status == '待审核'
                ? 'm-color-y'
                : ''
            "
            class="info-03 m-state"
          >
            {{ item.status }}
          </div>
        </div>
      </div>
    </van-list>
    <van-list
      class="funds-list"
      v-model="loading"
      :offset="15"
      :finished="finished"
      @load="infinite"
      v-if="tabIndex == 2"
      finished-text="暂无更多..."
    >
      <div class="funds-item" v-for="item in list" :key="item.orderNumber">
        <div class="item-date">
          <div class="m-left">订单号：{{ item.goldNo }}</div>
          <div class="m-right">{{ item.createTime }}</div>
        </div>
        <div class="item-info">
          <div class="info info-01">
            <p class="info-money">{{ item.mjMoney }}</p>
            <p class="info-name">金额(美元)</p>
          </div>
          <div class="info info-02">
            <p class="info-money">{{ item.receiptMode | formatModel }}</p>
            <p class="info-name">类型</p>
          </div>
          <!-- 出金状态 -->
          <template v-if="item.status == 16 && item.maker">
            <div class="info-03 m-state m-color-y2" @click="showTips = true">
              <img src="@/assets/images/funds/tip.png" alt="" />
              待确认
            </div>
          </template>
          <template v-else>
            <div
              v-if="item.goldStatus != '待审核'"
              :class="
                item.goldStatus == '出金成功'
                  ? 'm-color-green'
                  : item.goldStatus == '出金失败' ||
                    item.goldStatus == '资金不足' ||
                    item.goldStatus == '联系客服' ||
                    item.goldStatus == '退回MT4成功'
                  ? 'm-color-red'
                  : item.goldStatus == '订单取消'
                  ? 'm-color-y'
                  : ''
              "
              class="info-03 m-state"
            >
              {{ item.goldStatus }}
            </div>
            <div class="info-03" v-if="item.goldStatus == '待审核'">
              <span class="cancel" @click="cancelOut(item.id)">取消出金</span>
            </div>
          </template>
        </div>
        <!-- 撮合的子订单 -->
        <div class="children-list" v-if="item.clist" v-show="openId == item.id">
          <div
            class="list-info"
            v-for="citem in item.clist"
            :key="citem.subOrderCode"
          >
            <span class="list-money">分批汇款金额：￥{{ citem.amount }}</span>
            <span
              class="list-status list-status-1"
              v-if="citem.detailState == 1 && citem.channelType == 1"
              @click="handleOkOrder(citem)"
              >确认到账</span
            >
            <span
              class="list-status list-status-2"
              v-if="citem.detailState == 2"
              >已到账</span
            >
            <span
              class="list-status list-status-0"
              v-if="citem.detailState == 0"
              >支付中</span
            >
          </div>
        </div>
        <!-- 撮合的子订单只有在待确认状态和支付成功状态时显示 -->
        <div
          class="arrow"
          :class="item.clist && openId == item.id ? 'act' : ''"
          v-if="
            item.maker && (item.status == 16 || item.goldStatus == '出金成功')
          "
          @click="handleChildrenOrder(item)"
        >
          <img src="~@/assets/images/funds/arrow-down.png" />
        </div>
      </div>
    </van-list>
    <van-popup v-model="searchTimeShow" position="bottom" :overlay="true">
      <van-datetime-picker
        v-model="datetimepickerDate"
        :max-date="maxDate"
        type="date"
        @confirm="searchSave"
        @cancel="searchCancel"
      />
    </van-popup>
    <van-popup v-model="showTips" round :closeable="true" class="tips-pop">
      <div class="tips">
        由于银联限制，可能会导致需要分批汇款到您的账户，请您依次确认每笔资金已到账
      </div>
    </van-popup>
  </div>
</template>

<script>
import Tab from "@/components/tab.vue";
import funds from "@/apis/funds.js";
import { formatDate } from "@/utils/tool.js";
export default {
  name: "FundsDetails",
  components: {
    Tab,
  },
  data() {
    return {
      tabList: [
        {
          label: "入金明细",
          value: "1",
        },
        {
          label: "出金明细",
          value: "2",
        },
      ],
      searchDate: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tabIndex: 1, //选项卡默认值
      searchTimeShow: false,
      search: {
        btime: "",
        etime: "",
      },
      totalGold: 0,
      totalNum: 0,
      list: [],
      pageSize: 8,
      pageNo: 1,
      loading: false,
      finished: false,
      datetimepicker: "s",
      datetimepickerDate: "",
      openId: "", //展开撮合订单的id
      showTips: false, //是否显示撮合提示
      maxDate: new Date(),
    };
  },
  computed: {
    isWap() {
      return this.$store.state.common.isWap;
    },
  },
  filters: {
    formatModel(v) {
      let mode = "--";
      switch (+v) {
        case 1:
          mode = "银行卡";
          break;
        case 2:
          mode = "数字货币";
          break;
        case 3:
          mode = "微信";
          break;
        case 4:
          mode = "支付宝";
          break;
        case 6:
          mode = "数字人民币";
          break;
      }
      return mode;
    },
  },
  methods: {
    // 搜索
    handleSearch() {
      if (!this.searchDate || this.searchDate.length < 2) {
        this.$toast("请输入完整时间段");
        return;
      }

      let s = this.searchDate[0];
      let e = this.searchDate[1];
      let sD = new Date(s).getTime();
      let sE = new Date(e).getTime();
      let sF = (sE - sD) / (1000 * 60 * 60 * 24); //结束时间减去开始时间
      if (sF > 30) {
        this.$toast("时间间隔不能大于30天");
        return;
      }
      if (sF < 0) {
        this.$toast("结束时间不能小于开始时间");
        return;
      }

      this.list = [];
      this.totalGold = 0;
      this.totalNum = 0;
      this.pageNo = 1;
      this.loading = false;
      this.finished = false;
      this.infinite();
    },
    // 重置数据
    handleReset() {
      this.handleChangeTab(this.tabIndex);
    },
    //取消出金
    cancelOut(val) {
      let params = {
        id: val,
      };
      funds.giveup(params).then((res) => {
        if (res.IsSuccess) {
          this.$toast({
            forbidClick: true,
            message: res.Data,
          });
          setTimeout(() => {
            this.handleChangeTab(this.tabIndex);
          }, 2000);
        }
      });
    },
    // 无限加载数据
    infinite() {
      this.loading = true;
      this.getData();
    },
    // 切换顶部出入金
    handleChangeTab(index) {
      this.tabIndex = index;
      this.search.btime = "";
      this.search.etime = "";
      this.searchDate = [];
      this.list = [];
      this.totalGold = 0;
      this.totalNum = 0;
      this.pageNo = 1;
      this.loading = false;
      this.finished = false;
      this.infinite();
    },
    // 查询数据
    getData() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        beginCreateTime: this.searchDate[0]
          ? this.searchDate[0] + " 00:00:00"
          : undefined,
        endCreateTime: this.searchDate[1]
          ? this.searchDate[1] + " 23:59:59"
          : undefined,
      };
      if (this.tabIndex == 1) {
        this.getDepositList(params);
      } else {
        this.getOutGoldList(params);
      }
    },
    getDepositList(params) {
      funds.getDepositList(params).then((res) => {
        if (res.IsSuccess) {
          this.totalGold = res.Data.total;
          this.totalNum = res.Data.page.count;
          this.loading = false;
          this.pageNo += 1;
          if (typeof res.Data.page.list === "undefined") {
            this.finished = true;
            return;
          }
          this.list.push(...res.Data.page.list);
          if (this.list.length >= res.Data.page.count) {
            this.finished = true;
          }
        }
      });
    },
    getOutGoldList(params) {
      funds.getOutGoldList(params).then((res) => {
        if (res.IsSuccess) {
          this.totalGold = res.Data.totalGold;
          this.totalNum = res.Data.page.count;
          this.loading = false;
          this.pageNo += 1;
          if (typeof res.Data.page.list === "undefined") {
            this.finished = true;
            return;
          }
          this.list.push(...res.Data.page.list);
          if (this.list.length >= res.Data.page.count) {
            this.finished = true;
          }
        }
      });
    },
    datetimeInput(type) {
      //点击input时间面板赋值
      this.datetimepicker = type;
      this.datetimepickerDate = new Date();
      this.searchTimeShow = true;
    },
    //时间面板取消
    searchCancel() {
      this.searchTimeShow = false;
    },
    // 搜索时间设置
    searchSave(v) {
      let date = formatDate(v);
      switch (this.datetimepicker) {
        case "s":
          this.searchDate[0] = date.split(" ")[0];
          break;
        case "e":
          this.searchDate[1] = date.split(" ")[0];
          break;
      }
      this.searchTimeShow = false;
    },
    //上传凭证
    uploadCredentials(id) {
      console.log(id);
      let params = {
        orderId: id,
      };
      funds.getArtificialPayUrl(params).then((res) => {
        if (res.IsSuccess) {
          let openUrl = res.Data;
          window.location.href = openUrl;
        }
      });
    },
    //获取撮合订单
    handleChildrenOrder(item) {
      if (this.openId == item.id) {
        this.openId = ""; //关闭子订单
        return;
      }
      if (!item.clist) {
        this.$toast({
          type: "loading",
          mask: true,
          message: "加载中...",
          duration: 0,
        });
        funds
          .getWithdrawalDetail({ goldNo: item.goldNo })
          .then((res) => {
            this.$toast.clear();
            if (res.IsSuccess && res.Data.success) {
              this.$set(item, "clist", res.Data.result[0].details); //res.Data.result
              this.openId = item.id;
            } else {
              this.$toast("获取失败，请重试");
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toast.clear();
            this.$toast("请重试");
          });
      } else {
        this.openId = item.id;
      }
    },
    //撮合订单确认到账
    handleOkOrder(citem) {
      if (citem.detailState == 1 && citem.channelType == 1) {
        this.$dialog
          .confirm({
            title: "温馨提示",
            message: "请确认您的提现金额已全部到账！",
          })
          .then(() => {
            this.doOkOrder(citem);
          });
      } else {
        this.$toast("当前状态不允许出金确认！");
      }
    },
    doOkOrder(citem) {
      this.$toast({
        type: "loading",
        mask: true,
        message: "确认中...",
        duration: 0,
      });
      funds
        .paymentSuccessful({ orderNumber: citem.subOrderCode })
        .then((res) => {
          this.$toast.clear();
          if (res.IsSuccess && res.Data.success) {
            this.$set(citem, "detailState", 2); //修改订单状态
            this.$toast("确认收款成功");
          } else {
            this.$toast(res.Data.error.message || "确认收款失败，请重试");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.clear();
          this.$toast("请重试");
        });
    },
  },
  created() {
    if (this.$route.query.source == 2) {
      this.tabIndex = 2;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/funds/fundsDetails.scss";
</style>
